import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeDateToGivenFormat, getCurrentDate, getFormattedDate } from '../../../../../../../Utils/DateUtils';
import { AppConstant } from '../../../../../../Assests/AppConstant';
import { CustomDynamicForm } from '../../../../../Common/CustomForm/CustomDynamicForm';
import { getUserSelector } from '../../../../../../../redux/reducers/reducerSlices/UserReducer';
import { sagaActions } from '../../../../../../../sagas/sagaActions';
import { toast } from 'react-toastify';
import { changeFormatWithoutTz, initiateSearch, showAlert } from '../../../../../../Assests/Utility';
import { getWhMetaDataReducerSlice } from '../../../../../../../redux/reducers/reducerSlices/WhMetaDataReducerSlice';
import { fetchBreakListCommonFunc, fetchBreaksDocksListCommonFunc } from './Common/FetchBreakList';
import WarehouseSearch from '../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import Popup from '../../../../../Common/Popup/Popup';
import { fetchDockListCommonFunc } from '../DocksMeta/Common/FetchDockList';
import CustomDropDown from '../../../../../Common/CustomDropDown/CustomDropDown';
import moment from 'moment';
import _ from 'lodash-es';
import BreaksMetaList from './BreaksMetaList/BreaksMetaList';
import WarehouseSearchSS from '../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearchSS';
import SSPopup from '../../../../../Common/Popup/SSPopup/SSPopup';
import { CustomDynamicFormSS } from '../../../../../Common/CustomForm/CustomDynamicFormSS';
import BreaksMetaListSS from './BreaksMetaList/BreaksMetaListSS';
import CustomDropDownSS from '../../../../../Common/CustomDropDown/CustomDropDownSS';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { fetchTimeslotListCommonFunc } from '../DockDoorsForm/Common/FetchTimeslotsList';

const BreaksMetaDataFormSS = (props) => {

    const { themeSelected, allowPermissionE = true, allowPermissionD = true, whObj } = props;
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [searchStr, setSearchStr] = useState('');
    const [editData, setEditData] = useState({});
    const [loading, setLoading] = useState(false);
    const [resetFormData, setResetFormData] = useState(0);
    const [particularFieldValue, setParticularFieldValue] = useState({});
    const [startTimeState, setStartTimeState] = useState('');
    const [endTimeState, setEndTimeState] = useState('');
    const whMetaData = useSelector(getWhMetaDataReducerSlice);
    const { whMetaBreaksList, operationObj, whMetaDocksList,preCheckList,whMetaTimeslotList } = whMetaData;
    const user = useSelector(getUserSelector);
    const [isDockHidden, setIsDockHidden] = useState(false);
    const [isTimeSlotHidden, setIsTimeSlotHidden] = useState(false);
    const [currentFilterSelected, setCurrentFilterSelected] = useState({ name: 'Docks' });
    const { selectedWarehouse, cu_id } = user;
    const [showPreCheckModal, setShowPreCheckModal] = useState(false);
    const navigate = useNavigate()
    const firstUpdate = useRef(true);
    const [timeSlotOptions,setTimeSlotOptions] = useState(whMetaTimeslotList)
    const [selectedID,setSelectedID] = useState(null)


    useEffect(() => {
        setLoading(false);
        setShowModal(false);
        fetchDockList();
        fetchTimeslotList()
        
        dispatch({ type: sagaActions.UPDATE_PRE_CHECK_LIST, payload: [] })
    }, [])

    useEffect(()=>{
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
          }
        if (preCheckList.length>0) {
            setShowModal(false)
            setLoading(false)
            setShowPreCheckModal(true)
        }
    },[preCheckList])

    useEffect(()=>{
        if (selectedID) {
       
                const list= whMetaTimeslotList && whMetaTimeslotList.length && whMetaTimeslotList.filter((el)=>el.IDDock===selectedID)
                const finalList= list && list.length && list.map((el)=>{
                    let obj={
                        name: `${el.Name} - ${el.TimeSlotName}`,
                        value: el.ID
                    }
                    return obj
                })
              setTimeSlotOptions(finalList)
        }

    },[whMetaTimeslotList,selectedID])

    useEffect(() => {
        if (operationObj.type) {
            switch (operationObj.type) {
                case 'Add Break':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.breaksForm.createBreakSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.breaksForm.createBreakFailure);
                    }
                    break;
                case 'Edit Break':
                    if (operationObj.status) {
                        handleApiResponse(AppConstant.notifications.dataAddedSuccess, AppConstant.smartScheduler.breaksForm.updateBreakSuccess);
                    } else {
                        handleApiResponse(AppConstant.commonStrings.failure, AppConstant.smartScheduler.breaksForm.updateBreakFailure);
                    }
                    break;
                default:
            }
        }
    }, [operationObj])

    const fetchTimeslotList = () => {
        let { apiParams, operationObj } = fetchTimeslotListCommonFunc(whObj);
        dispatch({ type: sagaActions.WAREHOUSE_META_TIMESLOT_ACTION, payload: apiParams, operationObj: operationObj })
    }

    const handleApiResponse = (result, message) => {
        if (result === AppConstant.notifications.dataAddedSuccess) {
            showAlert(toast.TYPE.SUCCESS, message, themeSelected);
            setShowModal(false)
            fetchBreaksList()
        } else {
            showAlert(toast.TYPE.ERROR, message, themeSelected);
        }
        setLoading(false)
        dispatch({ type: sagaActions.RESET_SS_PO_OPERATION_OBJ });
    }

    const fetchDockList = () => {
        const { apiParams, operationObj } = fetchDockListCommonFunc(whObj);
        dispatch({ type: sagaActions.WAREHOUSE_META_DOCK_LIST_ACTION, payload: apiParams, operationObj: operationObj })
    };

    const fetchBreaksList = () => {
        if (currentFilterSelected.name === 'Warehouse') {
            const { apiParams, operationObj } = fetchBreakListCommonFunc(whObj);
            dispatch({ type: sagaActions.WAREHOUSE_META_BREAKS_LIST_ACTION, payload: apiParams, operationObj: operationObj })
        } else {
            fetchDocksBreaksList()
        }
    }

    const fetchDocksBreaksList = () => {
        const { apiParams, operationObj } = fetchBreaksDocksListCommonFunc(whObj, _.upperCase(currentFilterSelected?.name));
        dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_BREAKS_LIST_ACTION, payload: apiParams, operationObj: operationObj })
    }

    const TUSDatePickerInput = forwardRef(({ value, titleField, onClick }, ref) => {
        if (titleField === 'Start') {
            return (
                <button className="customDatePickerInput-ss" onClick={onClick} ref={ref}>
                    {particularFieldValue && particularFieldValue.BreakStart ? particularFieldValue.BreakStart : 'HH:MM'}
                </button>
            )
        } else if (titleField === 'End') {
            return (
                <button className="customDatePickerInput-ss" onClick={onClick} ref={ref}>
                    {particularFieldValue && particularFieldValue.BreakEnd ? particularFieldValue.BreakEnd : 'HH:MM'}
                </button>
            )
        }

    })

    const getFormFieldData = (data, name) => {

        if (name === 'BreakStart') {
            setStartTimeState(format(new Date(data), 'HH:mm'));
            setEndTimeState('');
            setParticularFieldValue({
                // ...particularFieldValue,
                'BreakStart': format(new Date(data), 'HH:mm'),
                'BreakEnd': particularFieldValue.BreakEnd ? particularFieldValue.BreakEnd : ""
            });

        } else if (name === 'BreakEnd') {
            setParticularFieldValue({
                ...particularFieldValue,
                'BreakEnd': format(new Date(data), 'HH:mm')
            });
            setEndTimeState(format(new Date(data), 'HH:mm'));
        }else if (name=== "IDDock") {
            setSelectedID(data)
        } else if (name === 'breaksForWhOrDock') {
            if (data === 'Warehouse') {
                setIsDockHidden(false);
                setIsTimeSlotHidden(false)
            }else if (data === 'Timeslot') {
                setIsTimeSlotHidden(true)
                setIsDockHidden(true);
            }else {
                setIsDockHidden(true);
                setIsTimeSlotHidden(false)
            }
            
        }
    };

    const editBreak = (data) => {
        const editData1 = {
            "IDWHBreak": data.IDWHBreak,
            "ID": data.ID,
            "BreakStart": data.BreakStart,
            "BreakEnd": data.BreakEnd,
            "Description": data.Description,
            "Status": data.Status,
            "CreatedBy": cu_id,
            "UpdatedBy": data.UpdatedBy,
            "CRUDTYPE": "U"
        }
        const tempData = {}
        if (currentFilterSelected.name === 'Warehouse') {
            tempData.breaksForWhOrDock = 'Warehouse';
        }else if (currentFilterSelected.name === 'Timeslot') {
            tempData.breaksForWhOrDock = 'Timeslot';
            tempData.breaksForWhOrDock = 'Docks';
            setIsDockHidden(true);
            setIsTimeSlotHidden(true)
        } else {
            tempData.breaksForWhOrDock = 'Docks';
            setIsDockHidden(true);
        }
        setShowModal(true);
        setEditData({ ...editData1, ...tempData });

        setStartTimeState(changeFormatWithoutTz(data.BreakStart, 'HH:mm'));
        setEndTimeState(changeFormatWithoutTz(data.BreakEnd, 'HH:mm'));
        setParticularFieldValue({
            ...data,
            'BreakStart': changeFormatWithoutTz(data.BreakStart, 'HH:mm'),
            'BreakEnd': changeFormatWithoutTz(data.BreakEnd, 'HH:mm'),
            ...tempData
        });
    }

    function convertTimeToDate(timeString) {
        const [hours, minutes] = timeString.split(':').map(Number);
        const currentDate = new Date();
        currentDate.setHours(hours, minutes, 0, 0);
        return currentDate;
    }

    const filterPassedTimeValue = (time) => {
        // return moment(startTimeState, "HH:mm").isBefore(moment(time, "HH:mm"))
        const now = new Date(convertTimeToDate(startTimeState));
        const selectedTime = new Date(time);
        const fifteenMinutesFromNow = new Date(now.getTime() + 15 * 60000); // 15 minutes in the future
    
        return selectedTime >= fifteenMinutesFromNow;
    };

    const getDockList = () => {
        const docksList = whMetaDocksList && whMetaDocksList.length && whMetaDocksList.map((ele, index) => {
            if (ele && ele.DockStatus) {
                return (
                    <option key={ele.Name} value={ele.ID}>
                        {ele.Name}
                    </option>
                );
            } else {
                return null;
            }
        })
        return _.compact(docksList);
    }

    // const getTimeslotList = () => {
    //     const timeslotList = whMetaTimeslotList && whMetaTimeslotList.length && whMetaTimeslotList.map((ele, index) => {
    //         if (ele && ele.DoorStatus) {
    //             return (
    //                 <option key={ele.Name} value={ele.ID}>
    //                     {ele.Name} - {ele.TimeSlotName}
    //                 </option>
    //             );
    //         } else {
    //             return null;
    //         }
    //     })
    //     return _.compact(timeslotList);
    // }

    const formFieldList = [
        {
            name: "breaksForWhOrDock",
            type: "radio",
            size: "col-sm-12 mt-2 radioButtonText",
            position: 0,
            title: 'Select for:',
            formValidation: {
                required: true,
            },
            radiconfig: [
            //     {
            //     label: "Warehouse",
            //     value: "Warehouse"
            // },
            {
                label: "Docks",
                value: "Docks"
            },
            {
                label: "Timeslot",
                value: "Timeslot"
            },
        ]

        },
        {
            name: "IDDock",
            type: "select",
            size: "col-sm-12",
            position: 4,
            title: 'Dock',
            hideElement: {
                name: 'IDDock',
                isHidden: isDockHidden,
            },
            formValidation: {
                required: isDockHidden,
            },
            getSelectData: getDockList,
        },
        {
            name: "IDTimeslot",
            type: "select",
            size: "col-sm-12",
            position: 4,
            title: 'Timeslot',
            hideElement: {
                name: 'IDTimeslot',
                isHidden: isTimeSlotHidden,
            },
            formValidation: {
                required: isTimeSlotHidden,
            },
            // getSelectData: getTimeslotList,
            optionsList: timeSlotOptions
        },
        {
            size: "col-sm-6 mt-1",
            name: "BreakStart",
            type: "custom-time-picker",
            customDatePickerConfig: {
                selectedDate: '',
                name: "BreakStart",
                showTimeSelect: true,
                showTimeSelectOnly: true,
                timeIntervals: 15,
                timeCaption: "Time",
                customInput: <TUSDatePickerInput value={startTimeState} titleField={'Start'} />,
            },
            position: 1,
            value: startTimeState,
            title: AppConstant.smartScheduler.breaksForm.tableHeaders.startTime,
            formValidation: {
                required: true,
            }
        },
        {
            size: "col-sm-6 mt-1",
            name: "BreakEnd",
            type: "custom-time-picker",
            customDatePickerConfig: {
                placeholder: 'HH:MM',
                selectedDate: '',
                startDate: '',
                name: "BreakEnd",
                showTimeSelect: true,
                showTimeSelectOnly: true,
                timeIntervals: 15,
                timeCaption: "Time",
                filterPassedTime: filterPassedTimeValue,
                customInput: <TUSDatePickerInput value={endTimeState} titleField={'End'} />,
            },
            position: 2,
            value: endTimeState,
            title: AppConstant.smartScheduler.breaksForm.tableHeaders.endTime,
            formValidation: {
                required: true,
            }
        },
        {
            name: "Description",
            type: "textarea",
            size: "col-sm-12",
            position: 3,
            title: 'Description',
            formValidation: {
                required: true,
            },
        },
    ]

    const formFieldEditList = [
        {
            name: "IDDock",
            type: "select",
            size: "col-sm-12",
            position: 4,
            title: 'Dock',
            hideElement: {
                name: 'IDDock',
                isHidden: isDockHidden,
            },
            formValidation: {
                required: isDockHidden,
            },
            getSelectData: getDockList,
        },
        {
            size: "col-sm-12 mt-1",
            name: "BreakStart",
            type: "custom-time-picker",
            customDatePickerConfig: {
                selectedDate: '',
                name: "BreakStart",
                timeFormat:'HH:mm',
                showTimeSelect: true,
                showTimeSelectOnly: true,
                timeIntervals: 15,
                timeCaption: "Time",
                customInput: <TUSDatePickerInput value={startTimeState} titleField={'Start'} />,
            },
            position: 1,
            value: startTimeState,
            title: AppConstant.smartScheduler.breaksForm.tableHeaders.startTime,
            formValidation: {
                required: true,
            }
        },
        {
            size: "col-sm-12 mt-1",
            name: "BreakEnd",
            type: "custom-time-picker",
            customDatePickerConfig: {
                placeholder: 'HH:MM',
                selectedDate: '',
                timeFormat:'HH:mm',
                startDate: '',
                name: "BreakEnd",
                showTimeSelect: true,
                showTimeSelectOnly: true,
                timeIntervals: 15,
                timeCaption: "Time",
                filterPassedTime: filterPassedTimeValue,
                customInput: <TUSDatePickerInput value={endTimeState} titleField={'End'} />,
            },
            position: 2,
            value: endTimeState,
            title: AppConstant.smartScheduler.breaksForm.tableHeaders.endTime,
            formValidation: {
                required: true,
            }
        },
        {
            name: "Description",
            type: "textarea",
            size: "col-sm-12",
            position: 3,
            title: 'Description',
            formValidation: {
                required: true,
            },
        },
    ]

    const createForm = () => {
        return (
            <CustomDynamicFormSS
                formFieldList={Object.keys(editData) && Object.keys(editData).length ? formFieldEditList : formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getSummitedFormData={getSummitedFormData}
                getFormFieldData={getFormFieldData}
                editFormData={editData}
                loading={loading}
                resetFormData={resetFormData}
                setParticularFieldValue={particularFieldValue}
            />
        );
    };

    const createPayload=(data,type)=>{
        if (type==="Edit") {
            return {
                "IDDockBreak": data.breaksForWhOrDock === "Docks" ? data.IDDockBreak : null,
                "IDWHBreak": data.breaksForWhOrDock === "Warehouse" ? data.IDWHBreak : null,
                "IDWarehouse": data.IDWarehouse,
                "BreakStart": data.BreakStart,
                "BreakEnd": data.BreakEnd,
                "Description": data.Description,
                "Status": data.Status,
                "CreatedBy": cu_id,
                "UpdatedBy": cu_id,
                "CreatedOn": data.CreatedOn,
                "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "CRUDTYPE": "U",
                "BreakType": data.breaksForWhOrDock.toUpperCase(),
                "IDTimeslot": data.breaksForWhOrDock !== "Warehouse" ? data.IDTimeslot : null,
                "IDDock": data.breaksForWhOrDock !== "Warehouse" ? data.IDDock : null,
            }
        }
        else{
            return {
                "IDDockBreak": null,
                "IDWHBreak": null,
                "IDWarehouse": whObj.ID,
                "BreakStart": data.BreakStart,
                "BreakEnd": data.BreakEnd,
                "Description": data.Description,
                "Status": 1,
                "CreatedBy": cu_id,
                "UpdatedBy": cu_id,
                "CreatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "UpdatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                "CRUDTYPE": "I",
                "BreakType": data.breaksForWhOrDock.toUpperCase(),
                "IDTimeslot": data.breaksForWhOrDock !== "Warehouse" ? data.IDTimeslot : null,
                "IDDock": data.breaksForWhOrDock !== "Warehouse" ? data.IDDock : null,
            }
        }
    }

    const getSummitedFormData = (data) => {
        setLoading(true);
        let apiParams = {}
        const operationObj = {
            type: '',
            status: '',
        }

        if (data.IDWarehouse) {
            const apiTempParams = createPayload(data,"Edit")
            operationObj.type = 'Edit Break'
            apiParams = { ...data, ...apiTempParams }
        } else {
            apiParams = createPayload(data,"Create")

            operationObj.type = 'Add Break'
        }

        if (data.breaksForWhOrDock === "Warehouse") {
            dispatch({ type: sagaActions.WAREHOUSE_META_BREAKS_LIST_ACTION, payload: apiParams, operationObj: operationObj })
        } else {
            dispatch({ type: sagaActions.WAREHOUSE_META_DOCKS_BREAKS_LIST_ACTION, payload: apiParams, operationObj: operationObj })
        }

    };

    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
            .saveButtonTitle,
    };

    const closeCurrentpopup = () => {
        setEditData({});
        setShowModal(false);
    }

    const showCreateBreakPopup = () => {
        const popupObj = {
            id: 'createCompanyGroupFormPopup1',
            modalLabel: 'createCompanyGroupFormPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: !Object.keys(editData).length ? AppConstant.smartScheduler.breaksForm.createBreakTitle : AppConstant.smartScheduler.breaksForm.editBreakTitle,
            bodyText: createForm(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }

    const openCreateForm = () => {
        setShowModal(true);
        setEditData({});
        setParticularFieldValue({});
    }

    const showCustomDropDownForFilters = () => {
        const filterDataArr = [
        
            // {
            //     name: 'Warehouse',
            // },
            {
                name: 'Docks',
            },
            {
                name: 'Timeslot',
            }
        ]
        return (
            <div className='d-flex align-items-center'>
                {/* <div className='me-2 titleFiltersLeaveMeta'>{AppConstant.commonStrings.select} :</div> */}
                <CustomDropDownSS themeSelected={themeSelected} data={filterDataArr} placeHolder={currentFilterSelected?.name} onSelect={(event) => setCurrentFilterSelected(event)} keyStr={'name'} />
            </div>
        )
    }

    const closePreCheckpopup = () => {
        setShowPreCheckModal(false)
    }

    const popupBtnHandler=(text)=>{
        if (text===AppConstant.commonStrings.viewDetailsStr) {
            navigate("/manageappointments")
        }
        else{
            setShowPreCheckModal(false)
        }
    }

    const showPreCheck = () => {
        const popupObj = {
            id: 'adminUpdateOperationPreCheck',
            modalLabel: 'adminUpdateOperationPreCheck',
            showClose: true,
            showfooter: true,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title:  AppConstant.smartScheduler.breaksForm.editBreakTitle,
            bodyText: AppConstant.superUserAdmin.warehouseAdmin.preCheckBockerMsg,            
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.commonStrings.viewDetailsStr
                },
                btn2: {
                    show: false,
                }
            },
        }

        return <SSPopup {...popupObj} popupBtnHandler={(text) => {popupBtnHandler(text)} } closepopup={()=>{closePreCheckpopup()}} themeSelected={themeSelected} showModal={showPreCheckModal} />
    }

    return (
        <div>
            <div className='container-fluid d-flex py-2 text-start justify-content-between mt-1 w-100'>
                <button
                    className={'btn-highlighted f-12'}
                    data-bs-toggle="modal"
                    onClick={() => openCreateForm()}
                >
                    {AppConstant.smartScheduler.breaksForm.createBreakTitle}
                </button>
                <div className='d-flex w-50 justify-content-end'>
                    {
                       currentFilterSelected?.name ? showCustomDropDownForFilters() : ''
                    }
                    <div className='ms-5'>
                        <WarehouseSearchSS
                            placeholder={'Search Breaks'}
                            inputValue={searchStr}
                            cancelSearch={() => setSearchStr('')}
                            onChangeHandler={(text) => setSearchStr(text)} />
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center breaksMetaList">
                <BreaksMetaListSS currentFilterSelected={currentFilterSelected} whObj={whObj} {...props} searchStr={searchStr} editBreakAction={(groupObj) => editBreak(groupObj)} showPopup={() => showCreateBreakPopup()} themeSelected={themeSelected} allowPermissionE={allowPermissionE} allowPermissionD={allowPermissionD} />
                {showCreateBreakPopup()}
                {showPreCheck()}
            </div>
        </div>
    )
}

export default BreaksMetaDataFormSS;