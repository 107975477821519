import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import _, { debounce } from 'lodash-es';
import { AppConstant } from "../../../Assests/AppConstant";
import {
    validateRequired,
    validateLength,
    validateEmail,
    validateContactNum,
    validateFieldIsNum,
    validatePassword,
    validateConfirmPassword,
    validateCarrierList,
    validateFieldMinValue,
    validateFieldIsText,
    validateStringIsOnlyNumber
} from "../../../../Utils/validationFunction";
import { toast } from "react-toastify";
import { showAlert } from "../../../Assests/Utility";
import FileUpload from "../CommanFileUpload/fileUpload";
import "./CustomDynamicFormSS.css";
import PropTypes from 'prop-types';
import { sagaActions } from "../../../../sagas/sagaActions";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { getFileUploadReducer } from "../../../../redux/reducers/reducerSlices/FileuploadReducer";
import CustomFieldWithTtileSS from "../CustomField/CustomFieldWithTtileSS";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import parsePhoneNumber from "libphonenumber-js";

/**
 * This is a reusable component that render form with New UI
 * @param {*} param
 * formFieldList = List of all element in form
 * submitbtn = Show Submit button true/false
 * cancelBtn = Show Cancel button true/false
 * getSummitedFormData = Handler function for submitted data
 * getFormFieldData = Handler function for form field data
 * editFormData = Editted form data
 * loading = Loader
 * setParticularFieldValue = set data in form elements
 * disclaimer = show disclaimer text true/false
 * disclaimersArray = disclaimer array
 * customBtn = custom buttons if any
 * customBtnHandler = Handler function for custom button handler
 * @returns 
 */
export const CustomDynamicFormSS = ({ formFieldList, sort, submitbtn, cancelBtn, themeSelected, getSummitedFormData, getFormFieldData, editFormData, loading, setParticularFieldValue, disclaimer, disclaimersArray, customDisableSave = false, customBtn, customBtnHandler,customBtnLoading=false,customBtnSubmit=false, searchableSelectFormData}) => {
    const fileUploadData = useSelector(getFileUploadReducer);

    const dispatch = useDispatch();
    //let [fieldArray,setFieldArray] = useState([...formFieldList]);
    let fieldArray = [...formFieldList]
    const [inputs, setInputs] = useState({});
    const [imageUploading, setImageUploading] = useState({ loading: false, name: "" });
    const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);
    const [isValid, setValid] = useState(false);

    const handleValid = (value) => {
      setValid(value);
  }
  
    const [initialLoad, setInitialLoad] = useState(false);
    //validation code
    let [errors, setErrors] = useState({});
    let [dirty, setDirty] = useState({});
    /**
     * Handler function to set initial data
     */
    const setInitialValidation = () => {
        let errorDataList = {};
        let dirtyData = {};
        let inputData = {};

        fieldArray.forEach((control) => {

            inputData[control.name] = "";
            errorDataList[control.name] = [];
            dirtyData[control.name] = false;
        });
        setInputs(JSON.parse(JSON.stringify(inputData)));
        setErrors(JSON.parse(JSON.stringify(errorDataList)));
        setDirty(JSON.parse(JSON.stringify(dirtyData)));
        setInitialLoad(true);
    };

    function handleDebounceFn(value) {
        if (handleValid)
            handleValid(value); // calling parent function to set valid ph no
    }
    /**
     * Initial useEffect
     */
    useEffect(() => {
        setInitialValidation();
    }, []);
     /**
     * useEffect for editFormData, initialLoad
     */
    useEffect(() => {
        if (editFormData)
            setInputs(editFormData);
    }, [editFormData, initialLoad])
  /**
   * useEffect for setParticularFieldValue, initialLoad
   */
    useEffect(() => {
        if (setParticularFieldValue != null && Object.keys(setParticularFieldValue)?.length > 0) {

            setInputs({ ...inputs, ...setParticularFieldValue });
        }

    }, [setParticularFieldValue, initialLoad])
    let handleChange = (data, name, control, searchableSelectFormData) => {
        if (typeof data === 'object') {
            data = data.toString();
        }
        if ((name === 'contact_number' || name === 'phone_number') && data.length > 14) {
            getFormFieldData && getFormFieldData(data.substring(0,14), name, control, inputs,searchableSelectFormData);
            setInputs((inputs) => ({ ...inputs, [name]: data.substring(0,14) }));
        }else{
        getFormFieldData && getFormFieldData(data, name, control, inputs, searchableSelectFormData);
        setInputs((inputs) => ({ ...inputs, [name]: data }));
        }
    };
  /**
   * Handler function for errors
   * @param {*} name 
   */
    const handleErrors = (name) => {
        setDirty((dirty) => ({ ...dirty, [name]: true }));
        validate(name);
    };
  /**
   * Validate form handler
   * @returns 
   */
    let validate = () => {
        let validArray = [];
        let errorData = {};
        let formValidation = {};
        fieldArray.forEach((control) => {
            errorData[control.name] = [];
            formValidation[control.name] = control.formValidation
        });
        Object.keys(errorData).forEach((control) => {
            let valid = true;
            if (formValidation[control]?.required) {
                valid = validateRequired(control, inputs[control], errorData);
            }
            if (inputs[control]) {

                if (formValidation[control]?.lengthValidation) {
                    valid = validateLength(
                        control,
                        inputs[control],
                        errorData,
                        formValidation[control]?.lengthValidation?.minLength,
                        formValidation[control]?.lengthValidation?.maxLength,
                        formValidation[control]?.lengthValidation.customError
                    );
                }
                if (formValidation[control]?.validateEmail) {
                    valid = validateEmail(
                        control,
                        inputs[control],
                        errorData
                    );
                }
                if (formValidation[control]?.validateContactNum) {
                    valid = validateContactNum(
                        control,
                        inputs[control],
                        errorData,
                        formValidation[control]?.lengthValidation?.minLength
                    );

                }
                if (formValidation[control]?.checkPassword) {

                    valid = validatePassword(
                        control,
                        inputs[control],
                        errorData
                    );
                }
                if (formValidation[control]?.confirmPassword) {
                    valid = validateConfirmPassword(
                        control,
                        inputs[control],
                        errorData,
                        formValidation[control].passwordText,
                    );
                }
                if (formValidation[control]?.allowOnlyNumber) {
                    valid = validateFieldIsNum(control, inputs[control], errorData);
                }
                if (formValidation[control]?.allowOnlyText) {
                    valid = validateFieldIsText(control, inputs[control], errorData);
                }
                if (formValidation[control]?.validateMinValue) {
                    valid = validateFieldMinValue(
                        control,
                        inputs[control],
                        errorData,
                        formValidation[control]?.validateMinValue.minValue,
                    );
                }
                if (formValidation[control]?.carrier_name) {
                    valid = validateCarrierList(control, inputs[control], errorData);
                }
                if (formValidation[control]?.numberType) {
                    valid = validateStringIsOnlyNumber(control, inputs[control], errorData);
                }
            }
            validArray.push(valid);
        });
        setErrors(errorData);
        if (validArray.includes(false)) {
            return false;
        }
        else {
            return true
        }

    };
  /**
   * useEffect for fileUploadData
   */
    useEffect(() => {
        if (fileUploadData?.data) {
            setImageUploading({ loading: false, name: "" });
            if (fileUploadData.data.name) {
                setInputs({
                    ...inputs,
                    [fileUploadData.data.name]: fileUploadData?.data?.fileUrl,
                });
                dispatch({ type: sagaActions.RESET_FILE_UPLOAD });
            }
        } else {
            if (fileUploadData?.error) {
                setImageUploading({ loading: false, name: "" });
                showAlert(toast.TYPE.ERROR, fileUploadData.error.message);
            }
        }

    }, [fileUploadData]);
  /**
   * Handler function for on file change
   * @param {*} fileData 
   * @param {*} name 
   */
    const onFileChange = async (fileData, name) => {
        setImageUploading({ loading: true, name: name });
        const fd = new FormData();
        fd.append("file", fileData);
        let fileUploadData = {
            name,
            fd
        }
        dispatch({ type: sagaActions.FILE_UPLOAD, payload: fileUploadData });

    };
  /**
   * Submit form handler function
   */
    const submitForm = async () => {
        let dirtyData = dirty;
        Object.keys(dirty).forEach((control) => {
            dirtyData[control] = true;
        });
        setDirty(dirtyData);
        if (validate()) {
                getSummitedFormData(inputs);
      
        }
    };

    const submitFormCustomBtn = async () => {
        let dirtyData = dirty;
        Object.keys(dirty).forEach((control) => {
            dirtyData[control] = true;
        });
        setDirty(dirtyData);
        if (validate()) {
                customBtnHandler(inputs)
        }
    };

  /**
   * Handler function for cancel form
   */
    const cancelForm = () => {
        setInitialValidation();
    };

    function handleValidation(valid) {
        debounceFn(valid);
      }

    const checkValidPhone = (value, country) => {
        value = value.slice(country.dialCode.length);
        const parsedNumber = parsePhoneNumber(value, country.iso2.toUpperCase());
        let valid = true;
        const isZero = value.length < 2;
        if (!isZero) {
            valid = parsedNumber?.isValid();
        }
    
        handleValidation(isZero ? false : valid);
    
        if (!valid && !isZero) {
            return 'Invalid phone: ' + value + ', ' + country.name;
        } else {
    
            return true;
        }
    }

    sort && fieldArray.sort((a, b) => {
        return a.position - b.position;
    });
    /**
   * function to render form UI
   * @param {*} control 
   * @returns 
   */
    const selectUI = (control) => {
        switch (control.type) {
            case "file":
                return (
                    <FileUpload
                        showError={
                            dirty[control.name] && errors[control.name][0]
                                ? errors[control.name]
                                : ""
                        }
                        accept={[control.accept]}
                        title={control.title}
                        name={control.name}
                        sizeConfig={AppConstant.help.imageTypen}
                        onFileChange={onFileChange}
                        isLoading={imageUploading}
                        showImageLogo={true}
                        imageSrc={inputs[control.name]}
                    />
                )
            case "select_with_input_search_add_button":
                return (
                    <>
                        <div className={`customInputTitle ${control.parentClass}`}>
                            <div>{control.titleIcon && <i className={control.titleIconClass}></i>} {control.inputTitle}</div>
                            <Select
                                className={`basic-single ${control.componentClassName}`}
                                classNamePrefix={control.classNamePrefix}
                                placeholder={`${control.placeholderText}`}
                                styles={control.customStylesLight}
                                onInputChange={control.onInputChange}
                                onChange={(data) => handleChange(data ? data.value : "", control.name, control)}
                                onBlur={() => handleErrors(control.name)}
                                // ref={props.ref}
                                defaultValue={control.defaultValue}
                                isLoading={control.isLoading}
                                isClearable={control.isClearable}
                                isSearchable={control.isSearchable}
                                options={control.options}
                                name={control.name}
                                formatCreateLabel={control.formatCreateLabelText}
                                loadingMessage={control.loadingMessage}
                                required={control?.formValidation?.required}
                                // noOptionsMessage={() => null}
                                // isValidNewOption={() => false}
                                // promptTextCreator={() => false}
                                // menuIsOpen={false}
                            />
                        </div>
                        <span className="errorMessage">
                            {
                                dirty[control.name] && errors[control.name][0]
                                    ? errors[control.name]
                                    : ""
                            }
                        </span>

                    </>
                )
                case "select_with_input_search_add_button_temp":
                    return (
                        <>
                            <div className={`customInputTitle ${control.parentClass}`}>
                                <div>{control.titleIcon && <i className={control.titleIconClass}></i>} {control.inputTitle}</div>
                                <CreatableSelect
                                    className={`basic-single ${control.componentClassName}`}
                                    classNamePrefix={control.classNamePrefix}
                                    placeholder={`${control.placeholderText}`}
                                    styles={control.customStylesLight}
                                    onInputChange={control.onInputChange}
                                    onChange={(data) => handleChange(data ? data.value : "", control.name, control, data)}
                                    onBlur={() => handleErrors(control.name)}
                                    // ref={props.ref}
                                    defaultValue={control.defaultValue}
                                    isLoading={control.isLoading}
                                    isClearable={control.isClearable}
                                    isSearchable={control.isSearchable}
                                    options={control.options}
                                    name={control.name}
                                    formatCreateLabel={control.formatCreateLabelText}
                                    loadingMessage={control.loadingMessage}
                                    required={control?.formValidation?.required}
                                    onCreateOption={control?.handleCreateOption}
                                    value={control?.searchableSelectFormData}
                                    isDisabled={control?.disabled}
                                />
                            </div>
                            <span className="errorMessage">
                                {
                                    dirty[control.name] && errors[control.name][0]
                                        ? errors[control.name]
                                        : ""
                                }
                            </span>
    
                        </>
                )
                case "select_with_input_search_add_button_withoutcreate":
                    return (
                      <>
                        <div className={`customInputTitle ${control.parentClass}`}>
                          <div>{control.inputTitle} {control.formValidation.required && <span className="color-red">*</span>}</div>
                          <Select
                            className={`basic-single ${control.componentClassName}`}
                            classNamePrefix={control.classNamePrefix}
                            placeholder={`${control.placeholderText}`}
                            styles={control.customStylesLight}
                            onInputChange={control.onInputChange}
                            onChange={(data) => handleChange(data ? data.value : "", control.name, control)}
                            onBlur={() => handleErrors(control.name)}
                            // ref={props.ref}
                            defaultValue={control.defaultValue}
                            isLoading={control.isLoading}
                            isClearable={control.isClearable}
                            isSearchable={control.isSearchable}
                            options={control.options}
                            name={control.name}
                            formatCreateLabel={control.formatCreateLabelText}
                            loadingMessage={control.loadingMessage}
                            required={control?.formValidation?.required}
                            value={control?.searchableSelectFormData}
                          />
                        </div>
                        <span className="errorMessage">
                          {
                            dirty[control.name] && errors[control.name][0]
                              ? errors[control.name]
                              : ""
                          }
                        </span>
            
                      </>
                    )
                    case "phone_with_country_code":
                        return (
                          <>
                            <div className={`inputTitle ${control.parentClass}`}>
                              <div>{control.title} {control.formValidation.required && <span className="color-red">*</span>}</div>
                              <PhoneInput
                              isValid={checkValidPhone}
                              country={'us'}
                              onlyCountries={control.activeCountriesListCodes}
                              value={inputs[control.name] ? inputs[control.name] : ""}
                              inputClass="form-control"
                              placeholder={control.placeHolder}
                              countryCodeEditable={false}
                                // onChange={(data, name) => handleChange(data, name, control)}
                                onChange={(data) => handleChange(data ? data : "", control.name, control)}
                              containerClass={"form-group"}
                          />
                            </div>
                            <span className="errorMessage">
                              {
                                dirty[control.name] && errors[control.name][0]
                                  ? errors[control.name]
                                  : ""
                              }
                            </span>
            
                          </>
                        )
            default:
                return (
                    <CustomFieldWithTtileSS
                        type={control.type}
                        showError={
                            dirty[control.name] && errors[control.name][0]
                                ? errors[control.name]
                                : ""
                        }
                        hideElement={control.hideElement}
                        name={control.name}
                        radiconfig={control.radiconfig}
                        customDatePickerConfig={control.customDatePickerConfig}
                        value={inputs[control.name] ? inputs[control.name] : ""}
                        handleErrors={handleErrors}
                        title={control.title}
                        required={control?.formValidation?.required}
                        onChange={(data, name) => handleChange(data, name, control)}
                        listData={control.getSelectData}
                        placeHolder={control.placeholder}
                        disabledField={control.disabledField}
                        addIcon={control?.formValidation?.addIcon}
                        iconHandler={control?.formValidation?.iconHandler}
                        className={control?.formValidation?.className}
                        titleIcon={control.titleIcon}
                        titleIconClass={control.titleIconClass}
                        editData={editFormData}
                        isEditDisable={control.isEditDisable}
                        optionsList={control.optionsList ? control.optionsList : null}
                    />
                )
        }
    }

    return (
        <div className="container-fluid py-2 " themeSelected={themeSelected} data-testid={AppConstant.dataTestId.customDynamicForm}>
            <div className="row gy-2">
                {fieldArray &&
                    fieldArray.map((control, index) => {
                        return (
                            <div className={control.size} key={index}>
                                <div className="">
                                    {selectUI(control, index)}
                                </div>
                            </div>
                        );
                    })}
            </div>
            {disclaimer && <div className="my-3 subText color-red">
                {
                    disclaimersArray && disclaimersArray.map((control, index) => {
                        return (<>{control.text && <div className="row" key={index}>
                            <div className="col-12">
                                <span className="color-red pe-1">*</span>
                                <span>{control.text}</span>
                            </div>
                        </div>}</>)
                    })
                }
            </div>}
            <div className="d-flex justify-content-end marbt-10 mt-3">
                {cancelBtn && (
                    <button
                        type="button"
                        className={`${cancelBtn.className} btn-unstyled ${themeSelected}`}
                        onClick={(e) => {
                            cancelForm(e, "close-btn");
                        }}
                    >
                        {cancelBtn.text}
                    </button>
                )}
                {customBtn && (
                    <button
                        type="button"
                        className={`btn-unstyled ${themeSelected} ${customBtn.className}`}
                        onClick={(e) => {
                            if (!customBtnSubmit) {
                                customBtnHandler(e, "customBtn");
                            }else{
                                submitFormCustomBtn(e, "close-btn");
                            }
                        }}
                    >
                         {
                            customBtnLoading &&
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" data-testid={AppConstant.dataTestId.loading} />
                        }
                        {customBtnLoading ? AppConstant.commonStrings.loading : customBtn.text}
                    </button>
                )}
                {submitbtn && (
                    <button
                        type="button"
                        className={`${submitbtn?.className} btn-styled ${themeSelected}`}
                        onClick={(e) => {
                            submitForm(e, "close-btn");
                        }}
                        disabled={loading | imageUploading | customDisableSave}
                    >
                        {
                            loading &&
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" data-testid={AppConstant.dataTestId.loading} />
                        }
                        {loading ? AppConstant.commonStrings.loading : submitbtn.text}
                    </button>
                )}

            </div>
        </div>
    );
};


CustomDynamicFormSS.propTypes = {
    formFieldList: PropTypes.array.isRequired,
    sort: PropTypes.bool,
    submitbtn: PropTypes.object.isRequired,
    cancelBtn: PropTypes.object,
    getSummitedFormData: PropTypes.func.isRequired,
    themeSelected: PropTypes.string.isRequired,
    getFormFieldData: PropTypes.func,
    editFormData: PropTypes.object,
    loading: PropTypes.bool,
    resetFormData: PropTypes.number
}