export const sagaActions = {
  // Executor Page
  INTAITE_GET_TRUCKSTS: 'INTAITE_GET_TRUCKSTS',
  FETCH_TRUCK_LIST: 'FETCH_TRUCK_LIST',
  AUTO_FETCH_PO_LIST: 'AUTO_FETCH_PO_LIST',
  LOAD_MORE_TRUCK_LIST: 'LOAD_MORE_TRUCK_LIST',
  INTAITE_GET_TRUCK: 'INTAITE_GET_TRUCK',
  SORT_TRUCK_LIST: 'SORT_TRUCK_LIST',
  UPDATE_TRUCK: 'UPDATE_TRUCK',
  INTIATE_TRUCK_DETAILS: 'INITIATE_TRUCK_DETAILS',
  LOAD_TRUCK_DETAILS: 'LOAD_TRUCK_DETAILS',
  RESET_LOAD_TRUCK_DETAILS: 'RESET_LOAD_TRUCK_DETAILS',
  GET_SELECTED_FILTERS_LIST: 'GET_SELECTED_FILTERS_LIST',
  ADD_FILTER: 'ADD_FILTER',
  DELETE_FILTER: 'DELETE_FILTER',
  SET_FILTERS_EXPANDED: 'SET_FILTERS_EXPANDED',
  SET_PO_LIST_FILTERS: 'SET_PO_LIST_FILTERS',
  STORE_SELECTED_DATE: "STORE_SELECTED_DATE",
  SIGNIN: "SIGNIN",
  RECOVER_PASSWORD_INITIATED: 'RECOVER_PASSWORD_INITIATED',
  RECOVER_PASSWORD_SUCCESS: "RECOVER_PASSWORD_SUCCESS",
  RESET_PASSWORD_INTIATED: 'RESET_PASSWORD_INTIATED',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  CLEAR_SIGNIN_ERROR: "CLEAR_SIGNIN_ERROR",
  CLEAR_SIGNEDIN_USER: "CLEAR_SIGNEDIN_USER",
  SEARCH_TRUCK_LIST_SAGA: "SEARCH_TRUCK_LIST_SAGA",
  RESET_FILTERS: 'RESET_FILTERS',
  GET_DOOR: 'GET_DOOR',
  ASSIGN_DOOR: 'ASSIGN_DOOR',
  ALREADY_ASSIGNED_DOOR: 'ALREADY_ASSIGNED_DOOR',
  GET_ALL_COMMENTS: 'GET_ALL_COMMENTS',
  ADD_COMMENT: 'ADD_COMMENT',
  RESET_DOOR_ACKNOWLEDGMENT_TIMER: "RESET_DOOR_ACKNOWLEDGMENT_TIMER",
  CHANGE_DOOR: 'CHANGE_DOOR',
  REMOVE_ASSIGN_DOOR: 'REMOVE_ASSIGN_DOOR',
  INITIATE_PO_STATUS: "INITIATE_PO_STATUS",
  APPROVE_PO: "APPROVE_PO",
  ENABLE_DOOR_SECTION: "ENABLE_DOOR_SECTION",
  CLEAR_APPROVE_PO_STATUS: "CLEAR_APPROVE_PO_STATUS",
  CLEAR_COMMENTS: "CLEAR_COMMENTS",
  DOCK_LIST: "DOCK_LIST",
  CLEAR_DOCK: "CLEAR_DOCK",
  CLEAR_DOCK_ERROR: "CLEAR_DOCK_ERROR",
  ASSIGN_DOCK: "ASSIGN_DOCK",
  CLEAR_ASSIGN_DOCK: "CLEAR_ASSIGN_DOCK",
  CLEAR_ASSIGN_DOCK_ERROR: "CLEAR_ASSIGN_DOCK_ERROR",
  UPDATE_DOCK_ID: "UPDATE_DOCK_ID",
  SET_PASSWORD: "SET_PASSWORD",
  CLEAR_SET_PASSWORD: "CLEAR_SET_PASSWORD",
  REMEMBER_USER: "REMEMBER_USER",
  SET_DOCK_FILTERS_LIST: "SET_DOCK_FILTERS_LIST",
  SET_STATUS_FILTERS_LIST: "SET_STATUS_FILTERS_LIST",
  SELECTED_DOCK_FILTERS_LIST: "SELECTED_DOCK_FILTERS_LIST",
  SELECTED_STATUS_FILTERS_LIST: "SELECTED_STATUS_FILTERS_LIST",
  IS_SEARCHING: 'IS_SEARCHING',

  RELEASE_DOOR: 'RELEASE_DOOR',
  RESET_RELEASE_DOOR: "RESET_RELEASE_DOOR",
  INITIATE_CONFIRM_DOOR: 'INITIATE_CONFIRM_DOOR',
  UPDATE_CONFIRM_DOOR: 'UPDATE_CONFIRM_DOOR',
  UPDATE_CONFIRM_DOOR_LOADER: 'UPDATE_CONFIRM_DOOR_LOADER',

  // Default Dashboard
  INTIATE_AUTO_FETCH: 'INTIATE_AUTO_FETCH',
  GET_DASHBOARD_STATUS_TRUCK_LIST: "GET_DASHBOARD_STATUS_TRUCK_LIST",
  GET_DASHBOARD_STATUS_DRILLDOWN: "GET_DASHBOARD_STATUS_DRILLDOWN",
  SORT_DASHBOARD_STATUS_DRILLDOWN: "SORT_DASHBOARD_STATUS_DRILLDOWN",
  GET_UNLOADING_HOURS: "GET_UNLOADING_HOURS",
  GET_UNLOADING_HOURS_DRILLDOWN: "GET_UNLOADING_HOURS_DRILLDOWN",
  GET_DETENSTION_STATUS: "GET_DETENSTION_STATUS",
  GET_DETENSTION_STATUS_DRILLDOWN: "GET_DETENSTION_STATUS_DRILLDOWN",
  GET_APPOINTMENT_COMPLIANCE: 'GET_APPOINTMENT_COMPLIANCE',
  GET_APPOINTMENT_COMPLIANCE_DRILLDOWN: 'GET_APPOINTMENT_COMPLIANCE_DRILLDOWN',
  GET_TASK_ITEMS_DUE_LIST: 'GET_TASK_ITEMS_DUE_LIST',
  SET_TASK_ITEM: 'SET_TASK_ITEM', 
  SORT_DRILLDOWN_REPORT: 'SORT_DRILLDOWN_REPORT',
  INITIATE_DRILLDOWN_REPORT: 'INITIATE_DRILLDOWN_REPORT',
  UPDATE_SELECTED_BUTTON_OVERVIEW: 'UPDATE_SELECTED_BUTTON_OVERVIEW',
  TOP_10_LATE_LOADS: 'TOP_10_LATE_LOADS',
  UPDATE_SELECTED_BUTTON: 'UPDATE_SELECTED_BUTTON',
  UPDATE_SECTION_DATA: 'UPDATE_SECTION_DATA',
  GET_UNLOADING_TYPE_SUMMARY: 'GET_UNLOADING_TYPE_SUMMARY',
  GET_UNLOADING_TYPE_SUMMARY_DRILLDOWN: 'GET_UNLOADING_TYPE_SUMMARY_DRILLDOWN',
  GET_DOOR_USUAGE_SUMMARY_DRILLDOWN: 'GET_DOOR_USUAGE_SUMMARY_DRILLDOWN',
  RESET_DOOR_USUAGE_SUMMARY_DRILLDOWN: 'GET_DOOR_USUAGE_SUMMARY_DRILLDOWN',
  GET_DOOR_STATUS_DRILLDOWN: 'GET_DOOR_STATUS_DRILLDOWN',
  SORT_DOOR_ASSIGNMENT_DRILLDOWN: 'SORT_DOOR_ASSIGNMENT_DRILLDOWN',
  CLEAR_DOOR_STATUS_DRILLDOWN: 'CLEAR_DOOR_STATUS_DRILLDOWN',
  GET_TOP_10_LATE_LOADS_DRILLDOWN: 'GET_TOP_10_LATE_LOADS_DRILLDOWN',
  GET_UNSCHEDULED_POS_DRILLDOWN: 'GET_UNSCHEDULED_POS_DRILLDOWN',
  UPDATE_DATE_RANGE: 'UPDATE_DATE_RANGE',
  CUSTOM_DASHBOARD_DOCK_SELECTED: 'CUSTOM_DASHBOARD_DOCK_SELECTED',
  // CUSTOM DASHBOARD

  GET_DASHBOARD_WIDGET: 'GET_DASHBOARD_WIDGET',
  UPDATE_DASHBOARD_WIDGET: 'UPDATE_DASHBOARD_WIDGET',
  UPDATE_SELECTED_CUSTOM_WIDGETS: 'UPDATE_SELECTED_CUSTOM_WIDGETS',
  RESET_FUNNEL_GRAPH_DATA: 'RESET_FUNNEL_GRAPH_DATA',
  GET_DETENTION_PERCENTAGE: 'GET_DETENTION_PERCENTAGE',
  UPDATE_DOOR_USAGE_SUMMARY: 'UPDATE_DOOR_USAGE_SUMMARY',
  GET_DOOR_STATUS_WIDGET: 'GET_DOOR_STATUS_WIDGET',
  GET_DOOR_ASSIGNMENT: 'GET_DOOR_ASSIGNMENT',
  DOOR_ASSIGNMENT_LOADER: 'DOOR_ASSIGNMENT_LOADER',
  RESET_DOOR_ASSIGNMENT_DRILLDOWN: 'GET_DOOR_ASSIGNMENT_DRILLDOWN',
  WIDGET_UNSCHEDULED_POS: 'WIDGET_UNSCHEDULED_POS',
  TOP_10_SELECTED_LATE_LOAD: 'TOP_10_SELECTED_LATE_LOAD',
  GET_AUTO_FETCH_CUSTOM_DASHBOARD_INTERVAL: 'GET_AUTO_FETCH_CUSTOM_DASHBOARD_INTERVAL',
  INITIATE_SUPER_ADMIN_LOADING: 'INITIATE_SUPER_ADMIN_LOADING',
  GET_AUTO_FETCH_NOTIFICATION_INTERVAL: 'GET_AUTO_FETCH_NOTIFICATION_INTERVAL',

  //Super Admin
  GET_WAREHOUSE_LIST: 'GET_WAREHOUSE_LIST',
  GET_ROLES_LIST: 'GET_ROLES_LIST',
  ROLE_LIST_ERROR: 'ROLE_LIST_ERROR',
  GET_ROLES_FEATURE_LIST: 'GET_ROLES_FEATURE_LIST',

  ASSIGN_ROLES_FEATURE_LIST: 'ASSIGN_ROLES_FEATURE_LIST',
  SORT_SUPER_ADMIN_TABLE: 'SORT_SUPER_ADMIN_TABLE',
  UPDATE_ISSUES_LIST: 'UPDATE_ISSUES_LIST',
  UPDATE_FEEDBACK_LIST: 'UPDATE_FEEDBACK_LIST',
  WAREHOUSE_LIST_SORTING: 'WAREHOUSE_LIST_SORTING',
  GET_REQUESTS_RECEIVED_DROPDOWNS_LIST: 'GET_REQUESTS_RECEIVED_DROPDOWNS_LIST',
  GET_OPEN_NOTIFICATION_LIST: 'GET_OPEN_NOTIFICATION_LIST',



  //REMOTE CONFIG
  GET_AUTO_FETCH_EXECUTOR_INTERVAL: 'GET_AUTO_FETCH_EXECUTOR_INTERVAL',
  GET_AUTO_FETCH_DEF_DASHBOARD_INTERVAL: 'GET_AUTO_FETCH_DEF_DASHBOARD_INTERVAL',
  GET_DOOR_ASSIGNED_TIMER: 'GET_DOOR_ASSIGNED_TIMER',

  //User Permissons
  GET_USER_TYPE: 'GET_USER_TYPE',
  GET_USER_PERMISSIONS: 'GET_USER_PERMISSIONS',
  UPDATE_NAV_BAR: 'UPDATE_NAV_BAR',
  RESET_USER: 'RESET_USER',
  RESET_USER_WITHOUT_BRANDING: 'RESET_USER_WITHOUT_BRANDING',
  RESET_USER_PERMISSIONS: 'RESET_USER_PERMISSIONS',
  UPDATE_WAREHOUSE_LIST: 'UPDATE_WAREHOUSE_LIST',
  UPDATE_SELECTED_WAREHOUSE: 'UPDATE_SELECTED_WAREHOUSE',
  RESET_ALL_WAREHOUSE_LIST: 'RESET_ALL_WAREHOUSE_LIST',

  //help and feedback
  ADD_HELP: "ADD_HELP",
  ADD_FEEDBACK: "ADD_FEEDBACK",
  RESET_HELP_FEED: "RESET_HELP_FEED",
  FILE_UPLOAD: "FILE_UPLOAD",
  RESET_FILE_UPLOAD: "RESET_FILE_UPLOAD",
  COMPANY_GROUP: "COMPANY_GROUP",
  RESET_COMPANY_GROUP: "RESET_COMPANY_GROUP",
  COMPANY_DATA: "COMPANY_DATA",
  RESET_COMPANY_DATA: "RESET_COMPANY_DATA",
  GET_LUMPING_FACILTY_DATA: "GET_LUMPING_FACILTY_DATA",
  GET_TIME_ZONE_DATA: "GET_TIME_ZONE_DATA",
  GET_WAREHOUSE_LOCATION_LIST: "GET_WAREHOUSE_LOCATION_LIST",
  GET_TAX_CODE_DATA: "GET_TAX_CODE_DATA",
  GET_COUNTRY_DATA: "GET_COUNTRY_DATA",
  ADD_WAREHOUSE: "ADD_WAREHOUSE",
  RESET_WAREHOUSE: "RESET_WAREHOUSE",
  RESET_TAX_CODE_DATA: "RESET_TAX_CODE_DATA",
  GET_FAQ_LIST: 'GET_FAQ_LIST',
  UPDATE_ROLES_FORM_POPUP: 'UPDATE_ROLES_FORM_POPUP',

  //User
  REGISTER_USER: 'REGISTER_USER',
  DELETE_USER: 'DELETE_USER',
  REGISTER_CARRIER_SUB_USER: 'REGISTER_CARRIER_SUB_USER',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_CARRIER_SUB_USER: 'UPDATE_CARRIER_SUB_USER',
  INITIATE_LOADING: 'INITIATE_LOADING',
  UPDATE_USER_LIST: 'UPDATE_USER_LIST',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',
  UPDATE_USER_ROLE_ASSIGN: 'UPDATE_USER_ROLE_ASSIGN',
  UPDATE_COMPANY_GROUP: 'UPDATE_COMPANY_GROUP',
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  GET_COMPANY_LIST: 'GET_COMPANY_LIST',
  UPDATE_SELECTED_USER: 'UPDATE_SELECTED_USER',
  UPDATE_ALL_WAREHOUSE: 'UPDATE_ALL_WAREHOUSE',
  SORT_USER_TABLE: 'SORT_USER_TABLE',
  RESET_USER_ROLE_ASSIGN: 'RESET_USER_ROLE_ASSIGN',
  UPDATE_COMPANY_GROUP_FORM_POPUP: 'UPDATE_COMPANY_GROUP_FORM_POPUP',
  UPDATE_COMPANY_FORM_POPUP: 'UPDATE_COMPANY_FORM_POPUP',
  PARENT_WAREHOUSE: 'PARENT_WAREHOUSE',
  GET_ALL_WAREHOUSE: 'GET_ALL_WAREHOUSE',
  INITIATE_RESET_USER_PASSWORD: 'INITIATE_RESET_USER_PASSWORD',
  UPDATE_RESET_USER_PASSWORD: 'UPDATE_RESET_USER_PASSWORD',
  UPDATE_LAST_LOGON: 'UPDATE_LAST_LOGON',

  //Settings
  USER_CHANGE_PASSWORD: 'USER_CHANGE_PASSWORD',
  UPDATE_CHANGE_PASSWORD_SUCCESS: 'UPDATE_CHANGE_PASSWORD_SUCCESS',
  UPDATE_CHANGE_PASSWORD_ERROR: 'UPDATE_CHANGE_PASSWORD_ERROR',
  GET_USER_ACCOUNT_DETAILS: 'GET_USER_ACCOUNT_DETAILS',
  UPDATE_USER_ACCOUNT_DETAILS_RENEW: 'UPDATE_USER_ACCOUNT_DETAILS_RENEW',
  DOWNLOAD_REPORT: "DOWNLOAD_REPORT",
  CLOSE_COMMAN_DOWNLOAD_MESSAGE_POPUP: 'CLOSE_COMMAN_DOWNLOAD_MESSAGE_POPUP',

  //Branding
  INITIATE_BRANDING: 'INITIATE_BRANDING',
  INITIATE_BRANDING_SUCCESS: 'INITIATE_BRANDING_SUCCESS',
  INITIATE_BRANDING_ERROR: 'INITIATE_BRANDING_ERROR',
  INITIATE_BRANDING_LOADER: 'INITIATE_BRANDING_LOADER',
  CLEAR_BRANING: 'CLEAR_BRANING',

  //Reports
  INTIATE_LOADER_REPORTS: 'INTIATE_LOADER_REPORTS',
  GET_TRUCK_UNLOAD_SUMMARY_LIST: 'GET_TRUCK_UNLOAD_SUMMARY_LIST',
  GET_ADFAT_REPORT_LIST: 'GET_ADFAT_REPORT_LIST',
  CLEAR_ADFAT_REPORT_LIST: 'CLEAR_ADFAT_REPORT_LIST',
  GET_CARRIER_LIST: 'GET_CARRIER_LIST',
  GET_VENDOR_LIST: 'GET_VENDOR_LIST',
  GET_TRUCK_UNLOAD_STATS_LIST: 'GET_TRUCK_UNLOAD_STATS_LIST',
  GET_SCHEDULED_LOAD_STATUS_LIST: 'GET_SCHEDULED_LOAD_STATUS_LIST',
  INITIATE_RESEND_EMAIL: 'INITIATE_RESEND_EMAIL',
  UPDATE_RESEND_EMAIL: 'UPDATE_RESEND_EMAIL',
  UPDATE_NAVIGATION_API: 'UPDATE_NAVIGATION_API',
  UPDATE_SELECTED_REPORT: 'UPDATE_SELECTED_REPORT',
  UPDATE_FACILITY_RATINGS: 'UPDATE_FACILITY_RATINGS',
  UPDATE_USER_RATINGS: 'UPDATE_USER_RATINGS',
  RESET_FACILITY_RATINGS_ACTION: 'RESET_FACILITY_RATINGS_ACTION',
  RESET_USER_RATINGS_ACTION: 'RESET_USER_RATINGS_ACTION',



  //Smart Schedule
  INTIATE_SS_LOADER: 'INTIATE_SS_LOADER',
  INITIATE_SS_APPOINTMENT_LOADER: 'INITIATE_SS_APPOINTMENT_LOADER',
  INITIATE_SS_MULTIPLE_PO_LOADER: 'INITIATE_SS_MULTIPLE_PO_LOADER',
  INTIATE_SS_SEARCH_PO: 'INTIATE_SS_SEARCH_PO',
  UPDATE_SS_SEARCH_PO: 'UPDATE_SS_SEARCH_PO',
  ADD_SELECTED_DATE_FOR_APPT_UPDATE: 'ADD_SELECTED_DATE_FOR_APPT_UPDATE',
  UPDATE_SS_SEARCH_PO_ERROR: 'UPDATE_SS_SEARCH_PO_ERROR',
  SET_SS_SELECTED_PO_FOR_APPT: 'SET_SS_SELECTED_PO_FOR_APPT',
  CLEAR_SS_SEARCH_PO: 'CLEAR_SS_SEARCH_PO',
  ADD_APPOINTMENT: 'ADD_APPOINTMENT',
  ADD_APPOINTMENT_CONFIRMATION: 'ADD_APPOINTMENT_CONFIRMATION',
  CLEAR_APPOINTMENT: 'CLEAR_APPOINTMENT',
  GET_SCHEDULER_DATA: 'GET_SCHEDULER_DATA',
  GET_PAYTRACE_CLIENT_ID: 'GET_PAYTRACE_CLIENT_ID',
  HIT_PAYTRACE_PAYMENT_API: 'HIT_PAYTRACE_PAYMENT_API',
  RESET_PAYTRACE_DATA: 'RESET_PAYTRACE_DATA',
  SAVE_DATA_FROM_SIGNUP_LISTNER_TO_REDUX: 'SAVE_DATA_FROM_SIGNUP_LISTNER_TO_REDUX',
  CREATE_CARRIER_FROM_SIGNUP: 'CREATE_CARRIER_FROM_SIGNUP',
  CREATE_PO_SS: 'CREATE_PO_SS',
  UPDATE_PO_LIST: 'UPDATE_PO_LIST',
  RESET_SS_PO_OPERATION_OBJ: 'RESET_SS_PO_OPERATION_OBJ',
  SORT_SS_USER_TABLE: 'SORT_SS_USER_TABLE',
  INITIATE_WAREHOUSE_METADATA: 'INITIATE_WAREHOUSE_METADATA',
  SEND_MAIL_FOR_APPOINTMENT: 'SEND_MAIL_FOR_APPOINTMENT',
  RESET_SEND_MAIL_FOR_APPOINTMENT: 'RESET_SEND_MAIL_FOR_APPOINTMENT',
  ADD_REMOVE_PO: 'ADD_REMOVE_PO',
  WAREHOUSE_META_DOCK_LIST_ACTION: 'WAREHOUSE_META_DOCK_LIST_ACTION',
  WAREHOUSE_META_LEAVES_LIST_ACTION: 'WAREHOUSE_META_LEAVES_LIST_ACTION',
  WAREHOUSE_META_DOCKS_LEAVES_LIST_ACTION: 'WAREHOUSE_META_DOCKS_LEAVES_LIST_ACTION',
  WAREHOUSE_META_BREAKS_LIST_ACTION: 'WAREHOUSE_META_BREAKS_LIST_ACTION',
  WAREHOUSE_META_CAPACITY_ACTION: 'WAREHOUSE_META_CAPACITY_ACTION',
  WAREHOUSE_META_TIMESLOT_ACTION: 'WAREHOUSE_META_TIMESLOT_ACTION',
  SORT_WH_META_TABLE_ACTION: 'SORT_WH_META_TABLE_ACTION',
  SS_PO_EXCEL_UPLOAD_ACTION: 'SS_PO_EXCEL_UPLOAD_ACTION',
  CANCEL_SUBSCRIPTION_ACTION: 'CANCEL_SUBSCRIPTION_ACTION',
  RESET_CARRIER_FROM_SIGNUP_TEMP_DATA: 'RESET_CARRIER_FROM_SIGNUP_TEMP_DATA',
  RESET_CANCEL_SUBCRIPTION_OBJ: 'RESET_CANCEL_SUBCRIPTION_OBJ',
  SS_UPCOMING_PAST_APPTS_ACTION: 'SS_UPCOMING_PAST_APPTS_ACTION',
  SS_RECOMMENDATIONS_LIST_ACTION: 'SS_RECOMMENDATIONS_LIST_ACTION',
  SS_GET_CARRIER_LIST: 'SS_GET_CARRIER_LIST',
  MY_SUBSCRIPTIONS_LIST_ACTION: 'MY_SUBSCRIPTIONS_LIST_ACTION',
  MY_APPTS_LIST_ACTION: 'MY_APPTS_LIST_ACTION',
  RESET_APPTS_LIST_ACTION: 'RESET_APPTS_LIST_ACTION',
  RESET_SS_UPCOMING_PAST_APPTS_ACTION: 'RESET_SS_UPCOMING_PAST_APPTS_ACTION',
  RESET_WAREHOUSE_META_CAPACITY_ACTION: 'RESET_WAREHOUSE_META_CAPACITY_ACTION',
  RESET_PO_LIST: 'RESET_PO_LIST',
  WAREHOUSE_META_DOCKS_BREAKS_LIST_ACTION: 'WAREHOUSE_META_DOCKS_BREAKS_LIST_ACTION',
  DRIVER_CHECKIN: "DRIVER_CHECKIN",
  RESET_DRIVER_CHECKIN: "RESET_DRIVER_CHECKIN",
  SORT_DRIVER_ARRIVAL_TABLE: "SORT_DRIVER_ARRIVAL_TABLE",
  DRIVER_LIVE_TRUCK: "DRIVER_LIVE_TRUCK",
  SORT_DRIVER_LIVE_TRUCK_TABLE: "SORT_DRIVER_LIVE_TRUCK_TABLE",
  INTIATE_GUARD_SEARCH_PO: "INTIATE_GUARD_SEARCH_PO",
  WAREHOUSE_META_RESET_TIMESLOT_ACTION: 'WAREHOUSE_META_RESET_TIMESLOT_ACTION',
  RESET_WAREHOUSE_META_DOCK_LIST_ACTION: 'RESET_WAREHOUSE_META_DOCK_LIST_ACTION',
  INTIATE_GUARD__PO_APPOINTMENT: "INTIATE_GUARD__PO_APPOINTMENT",
  UPDATE_CARD_DETAILS: 'UPDATE_CARD_DETAILS',
  UPDATE_PRE_CHECK_LIST: 'UPDATE_PRE_CHECK_LIST',
  PRE_CHECK_FOR_ADMIN_OPERATION: 'PRE_CHECK_FOR_ADMIN_OPERATION',
  CLEAR_SMART_SCHEDULE_REDUCER_DATA: 'CLEAR_SMART_SCHEDULE_REDUCER_DATA',
  ADD_CARRIER_SCHEDULE_PRO: 'ADD_CARRIER_SCHEDULE_PRO',
  DRIVER_REPORT_EDIT_IN_OUT: 'DRIVER_REPORT_EDIT_IN_OUT',
  DRIVER_REPORT_EDIT_LIVE_TRUCK: 'DRIVER_REPORT_EDIT_LIVE_TRUCK',
  DRIVER_REPORT_EDIT_LOADER: 'DRIVER_REPORT_EDIT_LOADER', 
  APPOINTMENT_REPORT_SUMMARY: 'APPOINTMENT_REPORT_SUMMARY',
  GET_STATE_WITH_ZIPCODE: 'GET_STATE_WITH_ZIPCODE',
  GET_APPOINTMENT_HISTORY: 'GET_APPOINTMENT_HISTORY',
  UPDATE_START_DATE: 'UPDATE_START_DATE',
  UPDATE_END_DATE: 'UPDATE_END_DATE',
  GET_MC_DETAILS: 'GET_MC_DETAILS',
  RESET_MC_DETAILS: 'RESET_MC_DETAILS',
  UPDATE_DEFAULT_DOCK: 'UPDATE_DEFAULT_DOCK',
  GET_APPOINTMENT_REPORT_HISTORY:'GET_APPOINTMENT_REPORT_HISTORY',
  RESET_APPOINTMENT_REPORT_HISTORY:'RESET_APPOINTMENT_REPORT_HISTORY',
  VALIDATE_DATE_FOR_APPOINTMENT:'VALIDATE_DATE_FOR_APPOINTMENT',
  RESET_VALIDATE_DATE_FOR_APPOINTMENT:'RESET_VALIDATE_DATE_FOR_APPOINTMENT',
  GET_DOCK_CONFLICT_DETAILS:'GET_DOCK_CONFLICT_DETAILS',
  RESET_DOCK_CONFLICT_DETAILS:'RESET_DOCK_CONFLICT_DETAILS',
  DELETE_TIMESLOT_ACTION: 'DELETE_TIMESLOT_ACTION',
  DELETE_POS_AND_APPOINTMENTS:'DELETE_POS_AND_APPOINTMENTS',
  RESET_OPERATION_OBJECT_WH: 'RESET_OPERATION_OBJECT_WH',
  UPLOAD_APPT_DOCS: 'UPLOAD_APPT_DOCS',
  CLEAR_UPLOAD_APPT_DOCS: 'CLEAR_UPLOAD_APPT_DOCS',
  SPRO_ADD_WAREHOUSE: "SPRO_ADD_WAREHOUSE",
  SPRO_RESET_WAREHOUSE: "SPRO_RESET_WAREHOUSE",
  SPRO_GET_WAREHOUSE_LIST: "SPRO_GET_WAREHOUSE_LIST",
  SPRO_WAREHOUSE_LIST_SORTING: 'SPRO_WAREHOUSE_LIST_SORTING',
  SPRO_RESET_WAREHOUSE_LIST: "SPRO_RESET_WAREHOUSE_LIST",
  RESET_DELETE_APPT_RESPONSE: "RESET_DELETE_APPT_RESPONSE",
  DOCK_TYPE_LIST_ACTION: "DOCK_TYPE_LIST_ACTION", 
  RESET_META_DOCKS_LIST: "RESET_META_DOCKS_LIST",
  //CheckIn-IMS
  INTIATE_CHECKIN_SEARCH_PO: "INTIATE_CHECKIN_SEARCH_PO",
  ADD_CHECKIN: "ADD_CHECKIN",

  //SS Finance Report
  SS_FINANCE_REPORT_ACTION: "SS_FINANCE_REPORT_ACTION",
  RESET_FINANCE_REPORT_ACTION: "RESET_FINANCE_REPORT_ACTION",
  SORT_DRIVER_APPOINTMENT_TABLE: "SORT_DRIVER_APPOINTMENT_TABLE",
  GET_APPOINTMENT_TRACKER: 'GET_APPOINTMENT_TRACKER',
  ADD_APPOINTMENT_BY_DATE: 'ADD_APPOINTMENT_BY_DATE',
  CLEAR_APPOINTMENT_TRACKER: 'CLEAR_APPOINTMENT_TRACKER',

  //Facility Rating download
  FACILITY_RATIING_DOWNLOAD: "FACILITY_RATIING_DOWNLOAD",

  //User Rating download
  USER_RATIING_DOWNLOAD: "USER_RATIING_DOWNLOAD",


  //Download Report
  DOWNLOAD_REPORT_COMMON:"DOWNLOAD_REPORT_COMMON",

  //Configration
  GET_CONFIGRATION: "GET_CONFIGRATION",
  
  //Update real web socket change message
  UPDATE_WEB_SOCKET: "UPDATE_WEB_SOCKET",

  GET_SCHEDULE_UPLOAD_STATUS: "GET_SCHEDULE_UPLOAD_STATUS",
  SORT_SCHEDULE_UPLOAD_DATA: "SORT_SCHEDULE_UPLOAD_DATA",

  //Eclipse Ops
  GET_ECLIPSE_OPS_PAYMENT_LOAD_DETAILS:"GET_ECLIPSE_OPS_PAYMENT_LOAD_DETAILS",
  SHARE_RECEIPT_FOR_ECLIPSE_OPS_PAYMENT_LOAD:"SHARE_RECEIPT_FOR_ECLIPSE_OPS_PAYMENT_LOAD",
  CLEAR_ECLIPSE_OPS_PAYMENT_LIST:"CLEAR_ECLIPSE_OPS_PAYMENT_LIST",
  UPDATE_REAL_TIME_FS_PAYMENT_LIST:"UPDATE_REAL_TIME_FS_PAYMENT_LIST",
  INITIATE_FSPAY_LOADING:"INITIATE_FSPAY_LOADING",
  GET_MANUAL_PAYMENT_METHODS_LIST:"GET_MANUAL_PAYMENT_METHODS_LIST",

  //FS Warehouse
  GET_FS_WAREHOUSE_DETAILS:"GET_FS_WAREHOUSE_DETAILS",

  //FS Pay Warehouse
  GET_FS_PAY_WAREHOUSE_DETAILS:"GET_FS_PAY_WAREHOUSE_DETAILS",
  FS_PAY_MANUAL_PAYMENT:"FS_PAY_MANUAL_PAYMENT",
  GET_ECLIPSE_SERVICE_FEE:"GET_ECLIPSE_SERVICE_FEE",
  CLEAR_ECLIPSE_SERVICE_FEE:"CLEAR_ECLIPSE_SERVICE_FEE",
  INITIATE_FSPAY_LOADING: "INITIATE_FSPAY_LOADING",
  GET_FS_PAY_PO_HISTORY: "GET_FS_PAY_PO_HISTORY",
  RESET_FS_PAY_PO_HISTORY: "RESET_FS_PAY_PO_HISTORY",

  //GATE PASS
 GET_GATEPASS_DOCK_DETAILS: 'GET_GATEPASS_DOCK_DETAILS',
 CLEAR_GATEPASS_DOCK: "CLEAR_GATEPASS_DOCK",
 CLEAR_GATEPASS_DOCK_ERROR: "CLEAR_GATEPASS_DOCK_ERROR",
 GET_GATEPASS_DETAILS: "GET_GATEPASS_DETAILS",
 GET_WAREHOUSE_DETAILS_FOR_GATEPASS: 'GET_WAREHOUSE_DETAILS_FOR_GATEPASS',
 CLEAR_WAREHOUSE_DETAILS_FOR_GATEPASS: "CLEAR_WAREHOUSE_DETAILS_FOR_GATEPASS",
 CLEAR_WAREHOUSE_DETAILS_FOR_GATEPASS_ERROR: "CLEAR_WAREHOUSE_DETAILS_FOR_GATEPASS_ERROR",
 CLEAR_GATEPASS: "CLEAR_GATEPASS",

 // Po Intake Mangement
 GET_WAREHOUSE_LIST: "GET_WAREHOUSE_LIST",
 GET_DOCK_LIST_BY_WAREHOUSE:"GET_DOCK_LIST_BY_WAREHOUSE",
 GET_PO_INTAKE_MANGEMENT_DETAILS:"GET_PO_INTAKE_MANGEMENT_DETAILS",
 CLEAR_PO_INTAKE_RESPONSE_OBJECT: "CLEAR_PO_INTAKE_RESPONSE_OBJECT",
 GET_PO_INTAKE_LOGS: "GET_PO_INTAKE_LOGS",
 STORE_POINTAKE_LOG_PAYLOAD: "STORE_POINTAKE_LOG_PAYLOAD"
}
